<template>
  <div style="width: 100%">
    <v-card-text class="pb-0 pr-1 pl-1"
      ><v-row>
        <span style="display: flex; width: 100%" class="ml-3 mr-3 mb-2">
          <span style="width: 200px" class="mr-3">
            <v-select
              dense
              label="Sort"
              outlined
              rounded
              hide-details
              class="assign_select"
              height="32"
              :items="[
                { text: 'Date', value: 'latest_upload' },
                { text: 'Camera', value: 'name' },
                { text: 'Source', value: 'latest_upload_source' },
              ]"
              v-model="sort_field"
              flat
            >
              <template v-slot:append-outer>
                <v-icon
                  color="primary"
                  class="sort_icon"
                  @click="changeSortDir"
                  >{{
                    sort_direction
                      ? "mdi-arrow-down-circle"
                      : "mdi-arrow-up-circle"
                  }}</v-icon
                ></template
              >
            </v-select></span
          >

          <span style="width: 100px" class="mr-3">
            <v-select
              label="Grid"
              class="assign_select"
              height="32"
              :items="[3, 4, 5, 6, 8, 10, 12]"
              outlined
              rounded
              v-model="grid"
              dense
              flat
              hide-details
            ></v-select
          ></span>
          <span style="width: 200px" class="mr-3">
            <v-select
              label="Resolution"
              style="width: 200px"
              class="assign_select"
              height="32"
              :items="resolutions"
              v-model="resolution"
              dense
              outlined
              rounded
              flat
              hide-details
            ></v-select
          ></span>
          <v-spacer></v-spacer>
          <span class="mt-1"
            ><v-icon @click="refresh">mdi-refresh</v-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="set_borders(true)"
                  v-bind="attrs"
                  v-on="on"
                  v-if="!bordered"
                  >mdi-border-all-variant</v-icon
                >
                <v-icon
                  @click="set_borders(true)"
                  v-bind="attrs"
                  v-on="on"
                  v-else
                  color="primary"
                  >mdi-border-all-variant</v-icon
                >
              </template>
              <span style="display: block;"><span style="background-color: green; width: 20px; height: 10px; display: inline-block;"></span> 1 hour</span>
              <span style="display: block;"><span style="background-color: rgb(0, 0, 220); width: 20px; height: 10px; display: inline-block;"></span> 1 day</span>
              <span style="display: block;"><span style="background-color: rgb(220, 220, 0); width: 20px; height: 10px; display: inline-block;"></span> 1 month</span>
              <span style="display: block;"><span style="background-color: orange; width: 20px; height: 10px; display: inline-block;"></span> 1 month +</span>
            </v-tooltip>

            <v-icon @click="show_text = !show_text" v-if="!show_text"
              >mdi-format-text</v-icon
            >
            <v-icon @click="show_text = !show_text" v-else color="primary"
              >mdi-format-text</v-icon
            >
          </span></span
        >
      </v-row>
      <v-row class="ml-0 mr-0 pb-3" v-if="last_footage && load">
        <div id="grid-container-lf">
          <div
            class="pa-0 grid_container_f"
            v-for="(c, index) in last_footage"
            :key="index"
            @click="select_camera(index)"
          >
            <span style="display: flex" :class="age(c.latest_upload)[1]">
              <span
                class="cam_last_upload_date_grid"
                v-if="show_text && c.last_status_update && grid < 8"
                ><span class="darker_bckrnd">{{
                  c.last_status_update.split("T")[0]
                }}</span>
              </span>
              <span class="cam_name_grid" v-if="show_text"
                ><span class="darker_bckrnd">{{ c.name }}</span>
              </span>
              <span class="cam_model_grid" v-if="show_text"
                ><span class="darker_bckrnd">{{ c.model }}</span>
              </span>

              <vue-load-image style="width: 100%">
                <img
                  slot="image"
                  alt=""
                  :src="`${
                    cookie_bucket +
                    $store.getters.getCustomerID +
                    '/thumbnails/' +
                    c.id +
                    '_latest.jpg'
                  }`"
                  class="grid_img_f"
                />
                <img
                  slot="preloader"
                  class="grid_img_f"
                  src="@/assets/loader.png"
                />
                <img
                  slot="error"
                  class="grid_img_f"
                  src="@/assets/no_media_light.png"
                />
              </vue-load-image>
              <span class="cam_date_grid"
                ><span
                  class="darker_bckrnd"
                  v-if="c.latest_upload != '' && show_text"
                  >{{ age(c.latest_upload)[0] }}</span
                ></span
              >
              <span class="cam_index_grid" v-if="!show_text">
                <span class="darker_bckrnd">{{ index + 1 }}</span></span
              >
              <span
                class="cam_resolution_grid"
                v-if="c.latest_upload_resolution && show_text"
              >
                <span class="darker_bckrnd">{{
                  c.latest_upload_resolution
                }}</span></span
              ></span
            >
          </div>
        </div>
      </v-row>
      <div v-else-if="!load">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </v-card-text>


  </div>
</template>

<script>
//import JSZip from "jszip";
import moment from "moment";
import axios from "axios";
//import VideoPlayer from "@/components/video_player/VideoPlayer.vue";
import videojs from "video.js";
import { eventBus } from "../../main.js";
import Vue from "vue";
import VueLoadImage from "vue-load-image";
import { sortByKeyDESC, sortByKey } from "@/utilities/Sorts.js";

export default {
  components: {
    //VideoPlayer,
    "vue-load-image": VueLoadImage,
  },
  data() {
    return {
      refresh_status: "continue",
      customer: "All Customers",
      sort_direction: true,
      corruption: "Has Status",
      selected_index: 0,
      dialog: false,
      sort_field: "latest_upload",
      grid: 4,
      bordered: true,
      resolution: "All",
      show_text: true,
      load: false,
      loaded_images: {},
      selected_camera: {},
      selected_camera_broken_bool: false,
      selected_camera_cellular_enabled_bool: false,
      selected_camera_ftp_enabled_bool: false,
      selected_camera_reolinkcloud_enabled_bool: false,
      error: "",
      selected_video: {},
      selected_sub_window: 0,
      is_json: false,
      json_data: "",
      json_file_not_present: false,
      json_loading: false,
      videoFps: 0,
      portal: "user",
      navigation_button_change: false,
      resolutions: [],
      reset_playbackRate: false,
      modal_width: "1000px",
      camera_model: "",
      customerFirstName: "",
      customerLastName: "",
      json_file_url: "",

      opened_image: {},
    };
  },
  methods: {
    sortByKeyDESC,
    sortByKey,
    get_path(video) {
      if (!video) {
        return false;
      }
      let date = moment(video.latest_upload, "YYYY-MM-DDTHH:mm:ss");
      let year = date.format("YYYY");
      let month = date.format("MM");
      let day = date.format("DD");

      return `${video.customer_id}/${video.id}/${year}/${month}/${day}/${
        video.id
      }_${moment(video.latest_upload, "YYYY-MM-DDTHH:mm:ss").format(
        "YYYY-MM-DD_HH-mm-ss"
      )}`;
    },
    imageExists(image_url) {
      var image = new Image();
      image.src = image_url;

      if (image.width == 0) {
        return false;
      } else {
        return true;
      }
    },
    async playVideo() {
      var player = videojs(document.querySelector(".video-js"));
      const url = this.get_path(this.last_footage[this.selected_index]);
      this.videoFps =
        this.last_footage[this.selected_index]["latest_upload_fps"];
      this.camera_model =
        this.camera_name_object_2[
          this.last_footage[this.selected_index].camera_id
        ];
      this.customerFirstName = this.$store.getters.getUserDDB.first_name;
      this.customerLastName = this.$store.getters.getUserDDB.last_name;
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".m3u8";
      eventBus.$emit("sourceChanged", source);

      var res = await fetch(source, { credentials: "include" });
      if (res.status == 403) {
        var arr = url.replaceAll("+", "/").split("/");
        arr.splice(1, 0, "ftp");
        source = process.env.VUE_APP_COOKIE_BUCKET + arr.join("/") + ".m3u8";
        res = await fetch(source, { credentials: "include" });
      }
      const tn = await res.text();
      const tn_arr = tn.split(/#EXTINF:/);
      tn_arr.shift();
      tn_arr.pop();

      const duration_sec = 120;

      var v_tn = {};
      for (const i in tn_arr) {
        v_tn[
          parseInt(tn_arr[i].slice(-8, -4).replace("-", ""), 10) * duration_sec
        ] = {
          src:
            process.env.VUE_APP_COOKIE_BUCKET +
            url.replaceAll("+", "/").slice(0, -36) +
            "hls/" +
            tn_arr[i]
              .slice(10, 50)
              .replace(".", "")
              .replace("ts", "")
              .replace("t", "")
              .replace("s", "") +
            ".jpg",
        };
      }
      eventBus.$emit("thumbnailPicArray", v_tn);

      player.src({
        src: source,
        type: "application/x-mpegURL",
        withCredentials: true,
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
      this.site_name = this.$store.getters.getSites[0].name;
    },
    // },
    updateJsonLoading(newValue) {
      this.json_loading = newValue;
    },
    nav_to_table(device_id) {
      this.$router.push({
        name: "videos",
        params: {
          cameraname: device_id.replaceAll(" ", "++"),
        },
      });
    },
    async download(url) {
      var base64 = await axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) =>
          Buffer.from(response.data, "binary").toString("base64")
        );
      var img = new Image();
      img.src = "data:image/jpeg;base64, " + base64;
      return img;
    },
    set_borders(upd_bor) {
      if (upd_bor) {
        this.bordered = !this.bordered;
      }
      if (!this.bordered) {
        document.querySelectorAll(".lf_green_active").forEach(function (cl) {
          cl.classList.replace("lf_green_active", "lf_green");
        });
        document.querySelectorAll(".lf_blue_active").forEach(function (cl) {
          cl.classList.replace("lf_blue_active", "lf_blue");
        });
        document.querySelectorAll(".lf_yellow_active").forEach(function (cl) {
          cl.classList.replace("lf_yellow_active", "lf_yellow");
        });
        document.querySelectorAll(".lf_orange_active").forEach(function (cl) {
          cl.classList.replace("lf_orange_active", "lf_orange");
        });
      } else {
        document.querySelectorAll(".lf_green").forEach(function (cl) {
          cl.classList.replace("lf_green", "lf_green_active");
        });
        document.querySelectorAll(".lf_blue").forEach(function (cl) {
          cl.classList.replace("lf_blue", "lf_blue_active");
        });
        document.querySelectorAll(".lf_yellow").forEach(function (cl) {
          cl.classList.replace("lf_yellow", "lf_yellow_active");
        });
        document.querySelectorAll(".lf_orange").forEach(function (cl) {
          cl.classList.replace("lf_orange", "lf_orange_active");
        });
      }
    },

    dummy_string() {
      return "?dummy=" + String(Math.floor(Math.random() * 9999999));
    },

    refresh() {
      this.$store
        .dispatch("DDB_GET_LAST_FOOTAGE", this.filter_params)
        .then(() => {
          this.set_borders(false);
        });
      return false;
    },
    select_camera(index) {
      eventBus.$emit("view_last_footage", {index: index, cameras: [...this.last_footage]});
      // this.selected_index = index;
      // this.navigation_button_change = !this.navigation_button_change;
      // try {
      //   this.json_file_url = this.get_path(this.last_footage[index]);
      //   this.opened_image = (
      //     await axios.post(
      //       this.$store.getters.getHasuraParamsWrite.url,
      //       {
      //         query: hasura_queries.inference_media,
      //         variables: {
      //           where: {
      //             customer_id: { _eq: this.last_footage[index].customer_id },
      //             camera_id: { _eq: this.last_footage[index].id },
      //             media_timestamp: {
      //               _eq: this.last_footage[index].latest_upload,
      //             },
      //           },
      //         },
      //       },
      //       {
      //         headers: this.$store.getters.getHasuraParamsWrite.headers,
      //       }
      //     )
      //   ).data.data.inference_media[0];
      //   this.dialog = true;

      //   //this.playVideo();
      // } catch (error) {
      //   return error;
      // }
    },
    age(date) {
      if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) == 1
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "second",
          "lf_green_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "seconds",
          "lf_green_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) == 1
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "minute",
          "lf_green_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "minutes",
          "lf_green_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) == 1
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "hour",
          "lf_blue_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) < 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "hours",
          "lf_blue_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) == 1
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "day",
          "lf_yellow_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) < 30
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "days",
          "lf_yellow_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) == 1
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "month",
          "lf_orange_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) < 12
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "months",
          "lf_orange_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) <= 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "year",
          "lf_orange_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) > 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "years",
          "lf_orange_active",
        ];
      } else {
        return [false, false];
      }
    },
    changeSortDir() {
      this.sort_direction = !this.sort_direction;
    },
  },
  watch: {
    // selected_index() {
    //   this.navigation_button_change = !this.navigation_button_change;
    //   if (this.dialog) {
    //     try {
    //      // this.fetchJSON();
    //       this.playVideo();
    //     } catch {
    //       return false;
    //     }
    //   }
    // },
    grid() {
      document.getElementById("grid-container-lf").style.gridTemplateColumns =
        "repeat(" + String(this.grid) + ", 1fr)";
    },
    filter_params() {
      this.$store
        .dispatch("DDB_GET_LAST_FOOTAGE", this.filter_params)
        .then(() => {
          this.set_borders(false);
        });
    },

    allowed_operations() {
      if (
        !this.allowed_operations.includes("CameraRead") &&
        this.allowed_operations != "All"
      ) {
        this.$router.push("/");
      } else {
        let _this = this;
        setTimeout(() => {
          _this.$store
            .dispatch("DDB_GET_LAST_FOOTAGE", _this.filter_params)
            .then(() => {
              _this.set_borders(false);
              _this.load = true;
            });
        }, 1000);
      }
    },

    customer_id() {
      this.$store
        .dispatch("DDB_GET_LAST_FOOTAGE", this.filter_params)
        .then(() => {
          var res = [
            ...new Set(
              this.last_footage.map((item) => item.latest_upload_resolution)
            ),
          ];

          res.sort();
          res = res.filter((item) => item !== "");
          res.unshift("All");
          this.resolutions = res;

          this.load = true;
        });
      this.load = false;
    },
  },
  computed: {
    camera_name_object_2() {
      var cam_nicknames = {};
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cam_nicknames[this.$store.getters.getCameraObjects[i].id] =
            this.$store.getters.getCameraObjects[i].name;
        }
      }
      return cam_nicknames;
    },
    filter_params() {
      return {
        resolution: this.resolution,
        sort: this.sort_field,
        sort_direction: this.sort_direction ? "desc" : "asc",
      };
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    last_footage() {
      if (this.$store.getters.getLastFootage.length > 0) {
        return this.$store.getters.getLastFootage;
      }
      return [];
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
  },
  mounted() {
    if (this.customer_id) {
      this.$store
        .dispatch("DDB_GET_LAST_FOOTAGE", this.filter_params)
        .then(() => {
          var res = [
            ...new Set(
              this.last_footage.map((item) => item.latest_upload_resolution)
            ),
          ];

          res.sort();
          res = res.filter((item) => item !== "");
          res.unshift("All");
          this.resolutions = res;

          this.load = true;
        });
    }
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (this.dialog) {
        if (
          e.key == "ArrowRight" &&
          this.selected_index < this.last_footage.length - 1
        ) {
          this.selected_index += 1;
        } else if (e.key == "ArrowLeft" && this.selected_index > 0) {
          this.selected_index -= 1;
        }
      }
    });
  },
  destroyed() {
    this.refresh_status = "stop";
  },
};
</script>
<style lang="css" scoped>
#lf_btns {
  width: 75px !important;
  display: inline-block;
  float: right;
  margin-right: 6px;
  margin-top: 12px;
}
.lf_green_active {
  border: 2px solid green;
}
.lf_blue_active {
  border: 2px solid rgb(0, 0, 220);
}
.lf_yellow_active {
  border: 2px solid rgb(220, 220, 0);
}
.lf_orange_active {
  border: 2px solid orange;
}
#full_size_ct_div {
  width: 100%;
}
#date_sort {
  color: gray;
  float: left;
  padding-top: 2px;
  margin-right: 10px;
  font-size: 14px;
}
.sort_icon {
  margin-top: -3px;
  margin-left: -7px;
}
#icon_info {
  font-size: 10px;
  margin-top: -4px;
  height: 20px;
  padding-left: 6px;
}
.darker_bckrnd {
  background-color: rgba(0, 0, 0, 0.377);
  padding-left: 1px;
  padding-right: 1px;
}
.resolution_large_img {
  text-align: right;
  position: absolute;
  bottom: 36px;
  right: 36px;
  transform: translate(0%, 0%);
  color: #ffffff;
  font-size: 20px;
  width: 80%;
  user-select: none;
}
.age_large_img {
  text-align: left;
  position: absolute;
  bottom: 36px;
  left: 36px;
  transform: translate(0%, 0%);
  color: #ffffff;
  font-size: 20px;
  width: 80%;
  user-select: none;
}
.cam_name_grid {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: right;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cam_model_grid {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  top: 18px;
  text-align: right;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cam_last_upload_date_grid {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.customer_name_grid {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.source_name_grid {
  position: absolute;
  top: 33px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cam_date_grid {
  text-align: left;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 12px;
  width: 95%;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.377); */
}
.cam_resolution_grid {
  text-align: right;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 12px;
  width: 95%;
  user-select: none;
}
.cam_index_grid {
  text-align: right;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 14px;
  width: 95%;
  user-select: none;
}
.grid_container_f {
  position: relative;
  text-align: center;
}

.filter_conf {
  text-align: right;
}
#grid-container-lf {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}
.lu_tabs {
  float: none !important;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid_container_f:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px black;
  z-index: 2 !important;
}
.grid_container_f:hover > .cam_name_grid {
  z-index: 3 !important;
}

.select_grid {
  float: left;
  width: 100px;
}
.nav_arrows_f {
  float: right;
  width: 140px;
}
</style>
